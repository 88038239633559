<template>
  <div class="h-100" style="margin-top: 70px">
    <div class="text-center mb-3">
      <a href="/"><img src="images/logos/SmartMedicare.png" class="img-fluid" alt="" width="400px" /></a>
    </div>

    <div class="authincation h-100">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-5 col-sm-6 col-xs-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <!-- <div class="text-center mb-3">
                      <a href="/"
                        ><img src="images/logos/SmartMedicare.png" alt="" width="400px"
                      /></a>
                    </div> -->
                    <LoaderComponent v-show="isLoading" />

                    <h4 class="text-center mb-4 text-white">
                      Sign in your account
                    </h4>

                    <form @submit.prevent="loginUser">
                      <!-- <form @submit.prevent="testLogin"> -->
                      <div class="form-group">
                        <label class="mb-1 text-white"><strong>Email</strong></label>
                        <input type="email" class="form-control" placeholder="hello@example.com" v-model="auth.email" />
                      </div>
                      <div class="form-group">
                        <label class="mb-1 text-white"><strong>Password</strong></label>
                        <input type="password" class="form-control" placeholder="Password" v-model="auth.password" />
                      </div>
                      <div class="form-row d-flex justify-content-between mt-4 mb-2">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1 text-white">
                            <input type="checkbox" class="custom-control-input" id="basic_checkbox_1" />
                            <label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <a class="text-white" href="#">Forgot Password?</a>
                        </div>
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn bg-white text-success btn-block">
                          Sign Me In
                        </button>
                      </div>
                      <br>
                      <div class="text-center">
                        <button type="button" class="btn bg-white text-success btn-block" @click="goToSignUp">
                          Start Free 30 Days Trial
                        </button>
                      </div>
                    </form>

                  <div class="new-account mt-3">
                    <p class="text-white">
                      Can't Access?
                      <a class="text-white" href="/settings">Check Settings</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

         <!-- Modal starts -->
         <b-modal ref="my-modal" class="modal-title" hide-footer title="Unlock Efficiency: 30-Day Smartmedicare Hospital Management System Trial">
                      <div class="d-block text-center">
                        <p class="modal-text">Discover the efficiency of Smartmedicare Hospital Management System with our complimentary 30-day trial. Simplify healthcare management starting today</p>
                      </div>
                      <b-button class="custom-button" variant="outline-primary" block @click="goToSignUp">Sign Up</b-button>
      
        </b-modal>


        <b-modal ref="myModal" :class="{ 'is-active': trialPeriodExpired }" hide-footer title="Trial Period Expired">
            <div class="d-block text-center">
              <p class="modal-text">Your trial period has expired. Please sign up for a subscription to continue using
                our service.</p>
            </div>
            <b-button class="custom-button" variant="outline-primary" block @click="goToSignUp">Sign Up</b-button>
          </b-modal>


          </div>

        <div class="footer">
          <!-- <div class="col-md-8 offset-md-2"> -->
          <div class="copyright">
            <p>
              Copyright © Designed &amp; Developed by
              <a href="https://uniccongroup.com/" target="_blank">Uniccon</a>
              2023
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import LoaderComponent from "@/views/LoaderComponent.vue";

export default {
  components: {
    LoaderComponent,
  },
  data() {

    return {

      auth: {
        password: "",
        email: "",
      },
      errorMessage: "",
      response: "",
      isLoading: false,
      auth_user: "",
      user: null,
      trialPeriodExpired: false
    };

  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },

  methods: {

    goToSignUp() {
        this.$router.push('/signup');
    },

    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },

    loginUser() {
  this.isLoading = true;
  this.axios
    .post("/staff/auth/login", this.auth)
    .then((response) => {
      console.log(response);
      this.isLoading = false;
      let token = response.data.data.token;

      this.$store.dispatch("updateToken", token);
      this.$store.dispatch("updateUser", response.data.data.staff, "staff");

      localStorage.setItem("user_type", "staff");
      localStorage.setItem("user_staff", JSON.stringify(response.data.data.staff));
      localStorage.setItem("selected_facility", JSON.stringify(response.data.data.staff.facility));

      if (token) {
        localStorage.setItem("jwt", token);
        window.location.href = "/dashboard";
        // this.$router.push("/dashboard");
      }
    })
    .catch((error) => {
      console.log(error.response);
      this.isLoading = false;

      if (error.response && error.response.data && error.response.data.message === "Trial period has expired") {
        // Set trialPeriodExpired to true if trial period has expired
        this.trialPeriodExpired = true;
        console.log("trialPeriodExpired set to true");

        // Open the trial period expired modal
        this.$refs.myModal.show();
      } else {
        // Handle other errors as needed
        this.loginAdmin();
      }
    });
},

    loginAdmin() {
      this.isLoading = true;
      this.axios
        .post("/admin/auth/login", this.auth)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          let token = response.data.data.token;
          this.$store.dispatch("updateToken", token);
          this.$store.dispatch("updateUser", response.data.data.admin, "admin");

          localStorage.setItem("user_type", "admin");
          localStorage.setItem(
            "user_admin",
            JSON.stringify(response.data.data.admin)
          );
          if (token) {
            localStorage.setItem("jwt", token);
            // this.$router.push("/admin-dashboard");
            window.location.href = "/admin-dashboard";
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
          this.errorMessage = error

          this.$toastr.e(`${this.errorMessage}`);

          // this.$toasted.error("Email or Password Incorrect", {
          //   position: "top-center",
          //   duration: 3000,
          // });
        });
    },

    // .post("/admin/auth/login", this.auth)

    testLogin() {
      this.isLoading = true;
      fetch("http://localhost:8000/api/admin/auth/login", {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(this.auth),
      })
        .then((response) => response.json())
        .then((res) => {
          this.isLoading = false;
          console.log(res);
          this.$toastr.s(`${JSON.stringify(res)}`);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.s(`${JSON.stringify(error(error))}`);
        });
    },
  },
  created() { },
};
</script>

<style>

.custom-button {
  transition: background-color 0.3s;
  border-color: #1f8752!important;
  color: #1f8752!important;
  justify-content: left;
}

.custom-button:hover {
  background-color: #1f8752!important;
  color: #ffffff!important;
}

.modal-title{
  color: #1f8752;
}
.modal-text{
  padding: 10px;
}

</style>
