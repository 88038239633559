<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-sm-12">
            <PatientCard :patient="patient" />
          </div>
          <div class="col-md-8">
            <div class="card">
              <div class="card-header border-0 pb-3 mb-10">
                <h5 class="card-title">Add Record</h5>
                <br />
              </div>
              <br />


              <div class="row">
                <div class="col-md-4 mb-3">
                  <button class="btn btn-success btn-block p-3" data-toggle="modal" data-target="#specialConsult">
                    <i class="las la-briefcase-medical la-3x mr-2"></i> <br />Book Consultation
                  </button>
                </div>

                <div class="col-md-4 mb-3">
                  <button class="btn btn-success btn-block p-3" @click="refreshQuery">
                    <i class="las la-sync-alt la-3x mr-2"></i> <br /> Refresh
                  </button>
                </div>

                <div class="col-md-4 mb-3">
                  <button class="btn btn-success btn-block p-3" v-b-toggle.sidebar-right>
                    <i class="las la-procedures la-3x mr-2"></i> <br />Hospitalize
                  </button>
                </div>

                <div class="col-md-4 mb-3">
                  <button class="btn btn-success btn-block p-3" v-b-toggle.sidebar-lab>
                    <i class="las la-vials la-3x mr-2"></i> <br />Laboratory
                  </button>
                </div>

                <div class="col-md-4 mb-3">
                  <button class="btn btn-success btn-block p-3" v-b-toggle.sidebar-appointment>
                    <i class="las la-calendar la-3x mr-2"></i> <br /> Book Appointment
                  </button>
                </div>

                <div class="col-md-4 mb-3">
                  <button class="btn btn-success btn-block p-3" v-b-toggle.sidebar-condition>
                    <i class="las la-stethoscope la-3x mr-2"></i><br />  Diagnose Condition
                  </button>
                </div>
              </div>







              <div class="card-body">
                <form @submit.prevent="addRecordTransfer">
                  <div class="form-group">
                    <label class="text-black">Presenting Complaints </label>
                    <!-- <select class="form-control" v-model="record.title" required>
                      <option v-for="reason in activities" :key="reason.id" :value="reason">
                        {{ reason.description }}
                      </option>
                    </select> -->

                    <wysiwyg v-model="record.presenting_complaints" />
                  </div>

                  <div class="form-group">
                    <label class="text-black">History of Presenting Complaints</label>
                    <wysiwyg v-model="record.history_of_presenting_complaints" />
                  </div>

                  <div class="form-group">
                    <label class="text-black">Prescriptions </label>
                    <span class="text-success">(optional)</span>

                    <div class="row" v-for="(pres, index) in record.prescriptions" :key="pres">
                      <div class="col-md-6">
                        <label for="">Drug </label>

                        <v-select v-model="pres.drug" label="name" :reduce="(name) => name.name" class="form-control"
                          :options="drugs" />
                      </div>

                      <div class="col-md-2">
                        <label for="">Dose</label>
                        <input type="text" v-model="pres.dose" min="1" placeholder="" class="form-control" />
                      </div>

                      <div class="col-md-6">
                        <label for="">Price </label>

                        <v-select v-model="pres.drug" label="name" :reduce="(name) => name.amount" class="form-control"
                          :options="drugs" />
                      </div>

                      <div class="col-md-2">
                        <label for="">Days</label>
                        <input type="text" v-model="pres.days" min="1" placeholder="" class="form-control" />
                      </div>

                      <div class="col-md-4">
                        <a class="btn btn-success btn-sm mt-5 mr-1" @click="addArray">
                          <i class="las la-plus"></i>
                        </a>

                        <a class="btn btn-danger btn-sm mt-5" @click="reduceArray(index)">
                          <i class="las la-trash"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="text-black">Laboratory Order</label>
                    <wysiwyg v-model="record.lab" />
                  </div>

                  <div class="form-group">
                    <label class="text-black">Sketch Notes
                      <span class="text-success text-sm">(optional)</span></label>
                    <!-- <img :s
                      rc="record.brief" alt="" /> -->

                    <div class="col-lg-12">
                      <div class="card m-b-30">
                        <div class="card-body">
                          <VueSignaturePad width="100%" height="300px" ref="signaturePad" />

                          <div class="col-md-6 offset-md-3">
                            <a class="btn btn-outline-success mx-1" @click="save">
                              Save
                            </a>
                            <a class="btn btn-outline-primary mx-1" @click="undo">
                              Undo
                            </a>
                            <a class="btn btn-outline-dark mx-1" @click="clear">
                              Clear
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <h5 class="mb-4">
                      <i class="fa fa-paperclip"></i> Attachments
                    </h5>
                    <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                      v-on:vdropzone-sending="sendingEvent"
                    ></vue-dropzone>
                  </div> -->

                  <div class="form-group">
                    <label class="text-black">Direct Patient to ? </label>
                    <select class="form-control" v-model="current_visit.action_id" required>
                      <option v-for="activity in activities" v-bind:key="activity.id" :value="activity.id">
                        {{ activity.description }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <button type="submit" class="btn btn-success btn-block">
                      Submit <i class="la la-send"></i>
                    </button>

                    <!-- <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#addVisit"
                      class="btn btn-outline-success btn-block"
                    >
                      Proceed to Next Step
                      <i class="las la-angle-double-right"></i>
                    </a> -->
                  </div>
                </form>
              </div>
            </div>

            <b-sidebar id="sidebar-right" title="New In Patient (Hospitalization)" right shadow width="600px">
              <div class="px-3 py-2">
                <AddHospitalization />
              </div>
            </b-sidebar>

            <b-sidebar id="sidebar-appointment" title="Book Appointment" right shadow width="600px">
              <div class="px-3 py-2">
                <MakeAppointment :patient="patient" />
              </div>
            </b-sidebar>

            <b-sidebar id="sidebar-condition" title="Diagnose Condition" right shadow width="600px">
              <div class="px-3 py-2">
                <AddHealthCondition :patient="patient" />
              </div>
            </b-sidebar>

            <b-sidebar id="sidebar-lab" title="New Laboratory Test" right shadow width="600px">
              <div class="px-3 py-2">
                <AddLaboratoryTest :patient="patient" />
              </div>
            </b-sidebar>

            <!-- Transfer Visit Modal -->
            <div class="modal fade" id="addVisit">
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Next Step</h5>
                    <button type="button" class="close" data-dismiss="modal">
                      <span>&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="addRecordTransfer">
                      <div class="form-group">
                        <label class="text-black font-w500">Direct Patient to ?
                        </label>
                        <select class="form-control" v-model="current_visit.action_id">
                          <option v-for="activity in activities" v-bind:key="activity.id" :value="activity.id">
                            {{ activity.description }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group">
                        <button type="submit" class="btn btn-success btn-block">
                          Submit & Proceed

                          <i class="la la-save"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of Transfer Visit -->

            <!-- Specialialized Consultation Modal -->
            <div class="modal fade" id="specialConsult">
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Other Prescriptions</h5>
                    <button type="button" class="close" data-dismiss="modal">
                      <span>&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <specialized-consultation-form @updateParent="updateParent($event)" />
                  </div>
                </div>
              </div>
            </div>
            <!-- End of  Modal -->
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading :active.sync="isLoading" loader="spinner" :can-cancel="true" :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>
<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import AddHospitalization from "./AddHospitalization.vue";
import AddHealthCondition from "@/views/patients/components/AddHealthCondition.vue";
import MakeAppointment from "@/views/patients/MakeAppointment.vue";
import AddLaboratoryTest from "../patients/AddLaboratoryTest.vue";
import PatientCard from "@/views/records/PatientCard.vue";
import { PatientService } from "@/jsstore/patients";
import { RecordService } from "@/jsstore/records";
// import { reloadPage } from "@/functions/Settings";
import defaultSketch from "./default_sketch.json";
import SpecializedConsultationForm from "./components/SpecializedConsultationForm.vue";

export default {
  components: {
    Header,
    Loading,
    AddHospitalization,
    AddHealthCondition,
    MakeAppointment,
    AddLaboratoryTest,
    PatientCard,
    SpecializedConsultationForm,
  },
  data() {
    return {
      jsstore: new PatientService(),
      record_service: new RecordService(),
      reasons: ["Check-up", "Emergency", "Laboratory"],
      dosages: ["1 daily", "2 times daily", "3 times daily"],
      showactivities: false,
      isLoading: false,
      showForm: false,
      fullPage: true,
      current_visit: "",
      drugs: "",
      image_url: "",
      activities: "",
      patient: "",
      picture: "",
      records: "",
      prescriptions: [],
      optical: "",
      record: {
        patient_card_no: "",
        patient: "",
        patient_id: "",
        title: "",
        details: " ",
        brief: defaultSketch.note,
        prescriptions: [
          {
            drug: "",
            dose: "",
            frequency: "",
            days: "",
          },
        ],
        is_lab: false,
        lab: "",
        action: "end visit",
        action_id: "",
        next_id: "",
      },
      Visit: {
        Purpose: "",
      },
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD FILES",
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  beforeCreate() {
    // reloadPage();
  },
  beforeMount() { },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.record.brief = data;
      console.log(isEmpty);
      console.log(data);
      this.$toasted.success("Saved Successfully!", {
        position: "top-center",
        duration: 3000,
      });
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    addArray() {
      let lastOfArray = this.record.prescriptions.length - 1;
      if (this.record.prescriptions[lastOfArray].drug == "") {
        this.$toastr.e("Select Prescription", "Error!");
      } else {
        this.record.prescriptions.push({
          drug: "",
          dose: "",
          frequency: "",
          days: "",
        });
      }
    },
    reduceArray(index) {
      this.record.prescriptions.splice(index, 1);
    },
    getPatient() {
      if (this.$store.state.mode == true) {
        this.getPatientOnline();
      } else {
        this.getPatientOffline();
      }
    },
    getPatientOnline() {
      this.axios
        .get(`/staff/patients`, {
          params: {
            scope: `id:${this.$route.params.id}`,
          },
        })
        .then((response) => {
          this.patient = response.data.data.data[0];
          let patient = this.patient;

          if (patient.latest_visit != null) {
            this.record.title = patient.latest_visit.action;
          }
          this.getActiveVisit(patient);
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getPatientOffline() {
      console.log(JSON.stringify(this.$route.params) + "boo");
      let query = await this.jsstore.getPatientByCard(
        this.$route.params.card_no
      );
      this.patient = query[0];
      console.log(this.patient + "jsstore");
    },

    sendingEvent(file, xhr, formData) {
      formData.append("paramName", "some value or other");
      this.files = file;
    },

    addRecord() {
      if (this.$store.state.mode == true) {
        this.addRecordFinal();
      } else {
        this.addRecordOffline();
      }
    },
    addRecordOnline() {
      if (confirm("Are you Sure you want to Submit?")) {
        this.isLoading = true;
        this.save();
        var formData = new FormData();
        formData.append("Attachments", this.files);
        formData.append("staff_id", this.$user.id);
        formData.append("facility_id", this.$selected_facility.id);
        formData.append("patient_id", this.patient.id);
        formData.append("title", this.record.title.description);
        formData.append("action", this.record.title.description);
        formData.append(
          "specialized_prescription",
          JSON.stringify(this.optical)
        );
        formData.append("details", this.record.details);
        formData.append(
          "prescriptions",
          JSON.stringify(this.record.prescriptions)
        );
        formData.append("lab", this.record.lab);
        formData.append("brief", this.record.brief);
        formData.append("action", this.record.action);
        formData.append("next_id", this.record.next_id);
        formData.append("action_id", this.record.title.id);
        this.axios
          .post(`/staff/records`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$toastr.s("Record Created Successfully", "Success!");
            this.clearForm();

            //change visit status
            this.axios
              .post(`/staff/update-visit/status`, {
                id: this.current_visit.id,
                action_id: this.current_visit.action_id,
                status: "active",
                finished: false,
              })
              .then((response) => {
                console.log(response);
                window.location.href = `/all-records`;
              })
              .catch((error) => {
                console.error(error);
              });

            //End of Vist Status
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toastr.e("Error on submitting record", "Success!");
          });
      }
    },

    addRecordTransfer() {
      this.addRecordOnline();
    },

    addRecordFinal() {
      this.addRecordOnline();

      //change visit status
      this.axios
        .post(`/staff/update-visit/status`, {
          id: this.current_visit.id,
          action_id: this.current_visit.action_id,
          status: "completed",
          finished: true,
        })
        .then((response) => {
          console.log(response);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.error(error);
        });

      //End of Vist Status
    },

    async addRecordOffline() {
      try {
        console.log(JSON.stringify(this.$route.params) + "jsstore");
        this.record.title = this.record.title.description;
        this.record.patient = this.patient;
        this.record.patient_card_no = this.patient.card_no;
        let query = await this.record_service.addRecord(this.record);
        query;
        console.log(JSON.stringify(this.record) + "jsstore");
        this.$toastr.s("Record Created Successfully", "Success!");
        this.clearForm();
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },
    clearForm() {
      this.record.title = "";
      this.record.details = "";
      this.record.brief = "";
      this.record.lab = "";
      this.record.action = "";
      this.record.next_id = "";
      this.prescriptions = "";
    },
    getActivities() {
      if (this.$store.state.mode == false) {
        this.activities = {
          data: [
            {
              description: "General Consultation",
            },
            {
              description: "Laboratory tests",
            },
            {
              description: "Check-up",
            },
          ],
        };
      } else {
        // this.isLoading = true;
        this.axios
          .get(`/staff/all-actions/${this.$selected_facility.id}`)
          .then((response) => {
            this.activities = response.data;
            console.log(JSON.stringify(response) + "pp");
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            // this.isLoading = false;
          });
      }
    },

    updateParent(payload) {
      console.log(payload);
      this.optical = payload;
    },
    showUpdate() {
      this.showactivities = true;
    },
    getActiveVisit(patient) {
      this.axios
        .get(`/staff/active-visit/${patient.id}`)
        .then((response) => {
          this.current_visit = response.data;

          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    

    getDrugs() {
      this.axios
        .get(`/staff/all-drugs/1`)
        .then((response) => {
          this.drugs = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.$toastr.e("error on drugs", "Error!");
        });
    },
    refreshQuery() {
      this.getActivities();
      this.getPatient();
      this.getDrugs();
    }
  },
  created() {
    this.getActivities();
    this.getPatient();
    this.getDrugs();
  },
};
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
