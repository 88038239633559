<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex align-items-center mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">
              {{ $selected_facility.title }} Facility
              <!-- {{ package_json.version }} -->
            </h2>
            <p class="mb-0">Welcome back, {{ $user.firstname }}</p>
          </div>

          <div class="dropdown ml-auto mr-2">
            <a
              href="#"
              class="btn btn-success light sharp"
              data-toggle="dropdown"
              aria-expanded="true"
            >
              Quick Links

              <i class="las la-angle-down ml-2"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-item">
                <span role="button">
                  <a href="/add-patient">
                    <i class="las la-user-injured text-success mr-2"></i>

                    <span class="text-dark"> Register Patient </span>
                  </a>
                </span>
              </li>

              <li class="dropdown-item">
                <span role="button">
                  <a href="/patients/all">
                    <i class="las la-user-injured text-success mr-2"></i>

                    <span class="text-dark">All Patients</span>
                  </a>
                </span>
              </li>

              <li class="dropdown-item">
                <span role="button">
                  <a href="/emergency-calls">
                    <i class="las la-phone-volume text-success mr-2"></i>

                    <span class="text-dark"> Emergency Calls</span>
                  </a>
                </span>
              </li>

              <li class="dropdown-item">
                <span v-b-toggle.sidebar-stock class="text-dark">
                  <a
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Export"
                  >
                    <i class="las la-store text-success mr-2"></i>
                  </a>
                  Add Stock
                </span>
              </li>

              <li class="dropdown-item">
                <span v-b-toggle.sidebar-dispense class="text-dark">
                  <a
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Export"
                    ><i
                      class="las la-prescription-bottle-alt text-success mr-2"
                    ></i
                  ></a>
                  Dispense Drugs
                </span>
              </li>

              <li class="dropdown-item">
                <span role="button" class="text-dark">
                  <a href="/vaccinate-patients">
                    <i class="las la-syringe text-success mr-2"></i>
                  </a>
                  Vaccinations
                </span>
              </li>
            </ul>
          </div>
        </div>

        
        <Doctor v-if="role == 'Doctor'" :metrics="metrics" />
        <Nurse v-if="role == 'Nurse'" :metrics="metrics" />
        <Nurse v-if="role == 'Laboratory'" :metrics="metrics" />
        <Pharmacist v-if="role == 'Pharmacy'" :metrics="metrics" />
        <Accountant v-if="role == 'Accountant'" :metrics="metrics" />
        <Receptionist
          v-if="role == 'Reception' || role == 'Records'"
          :metrics="metrics"
        />
        <Driver v-if="role == 'Driver'" />

        <div class="col-md-12">
          <VisitsList />
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import packageJSON from "../../../package.json";
import "vue-loading-overlay/dist/vue-loading.css";
// import { reloadPage } from "@/functions/Settings";
import Header from "@/views/Header.vue";
import Doctor from "./components/DoctorDashboard.vue";
import Nurse from "./components/NurseDashboard.vue";
import Pharmacist from "./components/PharmacistDashboard.vue";
import Accountant from "./components/AccountantDashboard.vue";
import Receptionist from "./components/ReceptionistDashboard.vue";
import Driver from "@/views/personnels/components//DriverDashboard.vue";
import VisitsList from "./components/VisitsList.vue";

export default {
  components: {
    Header,
    Loading,
    Doctor,
    Nurse,
    Pharmacist,
    Accountant,
    Driver,
    Receptionist,
    VisitsList,
  },
  data() {
    return {
      package_json: packageJSON,
      isLoading: false,
      mydownload:
        "https://drive.google.com/uc?export=download&id=1G00HlrRrIEk2a4i32qab-TgCpvHPkxsIn8QhoM_3lOU",
      fullPage: true,
      role: this.$user.job_title.name,
      checked: true,
      selected_facility: null,
      metrics: "",
      auth_user: "",
    };
  },
  beforeCreate() {
    //  reloadPage();
  },
  beforeMount() {},


  
  methods: {
    getMetrics() {
      this.axios
        .get(`/staff/hospitaldashboard/${this.$selected_facility.id}`)
        .then((response) => {
          this.metrics = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.getMetrics();
  },
};
</script>
