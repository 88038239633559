<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="settings-form">
            <div class="card-header text-center">
              <h4 class="text-success">Update Account</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateUser" v-if="$user_type == 'staff'">
                <div class="form-row">
                  <div
                    class="form-group col-md-12"
                    v-if="$user_type == 'admin'"
                  >
                    <label>Full Name </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>First Name </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      v-model="auth_user.first_name"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Last Name </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      v-model="auth_user.last_name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Middle Name </label>
                    <input
                      type="text"
                      placeholder="Middle Name"
                      v-model="auth_user.middle_name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      class="form-control"
                      v-model="auth_user.email"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Password</label>
                    <input
                      required
                      type="password"
                      placeholder="Password"
                      class="form-control"
                      v-model="auth_user.password"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="auth_user.phone_no"
                    />
                  </div>
                </div>

                <button class="btn btn-success btn-block" type="submit">
                  Update Account <i class="la la-lock"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-header">Url Settings</div>
          <div class="card-body">
            <div class="form-group col-md-12">
              <label for="inputPassword4">Select Url </label>
              <v-select
                v-model="app_url"
                required
                :reduce="(name) => name.url"
                label="name"
                :options="urls"
              />
              <p class="mt-2 text-success" role="button" @click="show_url = !show_url">Can't Find your Url? Click to add</p>


            </div>
           

            <div class="form-group col-md-12" v-show="show_url">
              <label>App Url: {{ $app_url }} </label>
              <input
                type="text"
                placeholder="App Url"
                v-model="app_url"
                class="form-control"
              />
            </div>

            <div class="form-group">
              <button class="btn btn-success btn-block" @click="onSaveUrl">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      show_url: false,
      isLoading: false,
      fullPage: true,
      user: null,
      urls: [
        {
          name: "Live",
          url:"https://api.smartmedicares.com/api",
        },
        {
          name: "Network",
          url:"http://192.168.1.9:8000/api",
        },
        {
          name: "Local",
          url:"http://localhost:8000/api",
        },
        
        
      ],
      auth_user: "",
      app_url: "",
    };
  },
  beforeCreate() {
  },
  beforeMount() {},
  methods: {
    onSaveUrl() {
      if (this.app_url == "") {
        this.app_url = "https://api.smartmedicares.com/api";
      }

      // this.$store.dispatch('updateURL', this.app_url)
      localStorage.setItem("app_url", this.app_url);

      this.$toastr.s("App Updated Successfully!", "Success!");
      // this.$router.push("/");
      window.location.href = "/";
    },
    getSIngleAdmin() {
      this.isLoading = true;

      this.axios
        .get(`/admin/profile/${this.$user.id}`)
        .then((response) => {
          this.auth_user = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getSIngleStaff() {
      // this.isLoading = true;

      this.axios
        .get(`/staff/profile/${this.$user.id}`)
        .then((response) => {
          this.auth_user = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          // this.isLoading = false;
        });
    },

    updateUser() {
      this.isLoading = true;
      this.axios
        .put(`/staff/edit-staff/${this.$user.id}`, {
          department_id: this.auth_user.department_id,
          email: this.auth_user.email,
          facility_id: this.auth_user.facility_id,
          first_name: this.auth_user.first_name,
          last_name: this.auth_user.last_name,
          middle_name: this.auth_user.middle_name,
          password: this.auth_user.password,
          phone_no: this.auth_user.phone_no,
          staff_no: this.auth_user.staff_no,
          initials: this.auth_user.initials,
          job_title_id: this.auth_user.job_title_id,
          gender: this.auth_user.gender,
          dob: this.auth_user.dob,
        })
        .then((response) => {
          this.getSIngleStaff();
          this.$toastr.s("Account Updated Successfully!", "Success!");
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getSIngleStaff();
  },
};
</script>
