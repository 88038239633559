<template>
  <div>
    <div id="accordion-one" class="accordion accordion-primary">
      <div class="accordion__item">
        <div
          class="accordion__header rounded-lg"
          data-toggle="collapse"
          data-target="#default_collapseOne"
        >
          <span class="accordion__header--text">Patient Details</span>
          <span class="accordion__header--indicator"></span>
        </div>
        <div
          id="default_collapseOne"
          class="collapse accordion__body show"
          data-parent="#accordion-one"
        >
          <div class="accordion__body--text">
            <div class="card">
              <div class="card-body pb-0">
                <div class="profile-photo text-center">
                  <img
                    :src="`${image_url}${patient.image}`"
                    alt=""
                    width="100"
                    class="img-fluid rounded-circle"
                    v-if="patient.image != null"
                  />

                  <img
                    v-else
                    src="/unknown_user.png"
                    width="100"
                    class="img-fluid rounded-circle"
                    alt=""
                  />
                </div>

                <p class="text-center">
                  <router-link :to="`/patient-${patient.id}`" class="btn-link">
                    {{ patient.first_name }}
                    {{ patient.last_name }}
                  </router-link>
                </p>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Gender</strong>
                    <span class="mb-0"> {{ patient.gender }} </span>
                  </li>
                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Card Number</strong>
                    <span class="mb-0">{{ patient.card_no }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Phone Number</strong>
                    <span class="mb-0">{{ patient.phone_no }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>E-mail</strong>
                    <span class="mb-0">{{ patient.email }}</span>
                  </li>
                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Age </strong>
                    <span class="mb-0">
                      {{ patient.dob | moment("from", "now", true) }} old
                    </span>
                  </li>
                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Blood Group</strong>
                    <span class="mb-0"> {{ patient.blood_group }} </span>
                  </li>

                  <li
                    v-if="patient.latest_vitals != null"
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Blood Pressure</strong>
                    <span class="mb-0">
                      {{ patient.latest_vitals.blood_pressure }}
                    </span>
                  </li>


                  <li
                    v-if="patient.latest_vitals != null"
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Temperature</strong>
                    <span class="mb-0">
                      {{ patient.latest_vitals.temperature }}
                    </span>
                  </li>

                  <li
                    v-if="patient.latest_vitals != null"
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Height</strong>
                    <span class="mb-0">
                      {{ patient.latest_vitals.height }}
                    </span>
                  </li>

                  <li
                    v-if="patient.latest_vitals != null"
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Pulse</strong>
                    <span class="mb-0">
                      {{ patient.latest_vitals.pulse }}
                    </span>
                  </li>
                  <li
                    v-if="patient.latest_vitals != null"
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Weight</strong>
                    <span class="mb-0">
                      {{ patient.latest_vitals.weight }}
                    </span>
                  </li>

                  <li
                    class="list-group-item d-flex px-0 justify-content-between"
                  >
                    <strong>Registered</strong>
                    <span class="mb-0"
                      >{{ patient.created_at | moment("from", "now") }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="card-footer pt-0 pb-0 text-center">
                <p>Patient Information Card</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="accordion__item"
        v-for="record in records"
        :key="record.title"
      >
        <div
          class="accordion__header collapsed rounded-lg"
          data-toggle="collapse"
          :data-target="`#default_collapseTwo${record.id}`"
        >
          <span class="accordion__header--text h6">
            {{ record.action.description }}
          </span>

          <span class="" style="font-size: 0.7rem">
            ({{ record.created_at | moment("MMMM Do YYYY") }})
          </span>
          <span class="accordion__header--indicator"></span>
        </div>
        <div
          :id="`default_collapseTwo${record.id}`"
          class="collapse accordion__body"
          data-parent="#accordion-one"
        >
          <div class="accordion__body--text">
            <div v-html="record.details" class="mb-2"></div>
          </div>

          <div class="accordion__body--text">
            <h5>Prescriptions</h5>
            <p
              class="mb-2"
              v-for="(pres, index) in JSON.parse(record.prescriptions)"
              :key="pres"
            >
              {{ index + 1 }}. {{ pres.drug }} at{{ pres.dose }} Dose,
              {{ pres.frequency }} times for {{ pres.days }} days.
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import RecordsJSON from "@/views/records.json";



export default {
  props: ["patient"],
  data() {
    return {
      records_json: RecordsJSON,
      image_url: "https://api.smartmedicares.com/patients/",
      records: "",
    };
  },

  methods: {
    // patientRecords() {
    //   this.axios
    //     .get(`/staff/patient-records/${this.patient.id}`)
    //     .then((response) => {
    //       this.records = response.data.data.data;
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },

    updateRecordDates() {
      //get record ids
      //check the id with json document
      //update records with corresponding matching id on json doc.

      this.records.forEach((record) => {
        this.updateDate(record);
      });
    },
    updateDate(record) {
      let toBeFiltered = this.records_json;
      console.log(JSON.stringify(record) + "record");

      let filtered = toBeFiltered.filter((x) => x.id == record.id);
      let csvRecord = filtered[0];

      console.log(csvRecord);

      this.axios
        .post(`/staff/update/record/date`, {
          id: csvRecord.id,
          date: csvRecord.created_at,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    // this.patientRecords();
  },
};
</script>