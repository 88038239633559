import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueToastr from "vue-toastr";
import Toasted from "vue-toasted";
import wysiwyg from "vue-wysiwyg";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import EasyCamera from "easy-vue-camera";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import ToggleButton from "vue-js-toggle-button";
import WebCam from "vue-web-cam";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import JsonExcel from "vue-json-excel";
import VueGoodTablePlugin from "vue-good-table";
var VueCookie = require("vue-cookie");
import VueApexCharts from "vue-apexcharts";
import VueSignature from "vue-signature-pad";
import VueSignaturePad from "vue-signature-pad";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-good-table/dist/vue-good-table.css";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(Toasted);
Vue.use(wysiwyg, {
  hideModules: {
    link: true,
    code: true,
    image: true,
    table: true,
    underline: true,
    removeFormat: true,
  },
}); // config is optional. more below
Vue.component("v-select", vSelect);
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});
Vue.use(EasyCamera);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(ToggleButton);
Vue.use(WebCam);
Vue.use(require("vue-moment"));
Vue.use(vueNumeralFilterInstaller);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueGoodTablePlugin);
Vue.use(VueCookie);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueSignature);
Vue.use(VueSignaturePad);

Vue.prototype.$app_url = localStorage.getItem("app_url");
Vue.prototype.$user_type = localStorage.getItem("user_type");
Vue.prototype.$selected_facility = JSON.parse(
  localStorage.getItem("selected_facility")
);

Vue.prototype.$user_admin = JSON.parse(localStorage.getItem("user_admin"));
Vue.prototype.$user_staff = JSON.parse(localStorage.getItem("user_staff"));
Vue.prototype.$user =
  Vue.prototype.$user_admin == null
    ? Vue.prototype.$user_staff
    : Vue.prototype.$user_admin;
window.axios = axios;
axios.defaults.baseURL =
Vue.prototype.$app_url == null
    ? "https://api.smartmedicares.com/api"
    : Vue.prototype.$app_url;
// axios.defaults.baseURL = "http://localhost:8000/api";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("jwt");
   "Bearer " + store.state.jwt;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

new Vue({
  router,
  store,
  beforeCreate() {
    // this.$store.commit("INITIALIZE_STORE");

  },

  render: (h) => h(App),
}).$mount("#app");
