import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Downloads from "../views/Downloads.vue";
import PageNotFound from "../views/extras/PageNotFound.vue"; 
import LockScreen from "../views/auth/LockScreen.vue";
import Login from "../views/auth/Login.vue";
import Signup from "../views/auth/Signup.vue";
import AdminLogin from "../views/auth/AdminLogin.vue";
import Profile from "../views/auth/Profile.vue";
import Settings from "../views/auth/Settings.vue";
import PersonnelDashboard from "../views/personnels/PersonnelDashboard.vue";
import AdminDashboard from "../views/personnels/AdminDashboard.vue";
import AllStaffs from "../views/personnels/AllStaffs.vue";
import SingleStaff from "../views/personnels/SingleStaff.vue";
import EditStaff from "../views/personnels/EditStaff.vue";
import AddFacility from "../views/facilities/AddFacility.vue";
import AddDepartment from "../views/departments/AddDepartment.vue";
import ManageDepartments from "../views/departments/ManageDepartments.vue";
import SingleDepartment from "../views/departments/SingleDepartment.vue";
import AllPatients from "../views/patients/AllPatients.vue";
import AddPatient from "../views/patients/AddPatient.vue";
import SinglePatient from "../views/patients/SinglePatient.vue";
import PatientRecords from "../views/patients/components/PatientRecords.vue";
import PatientLabs from "../views/patients/components/PatientLabs.vue";
import NextofKins from "../views/patients/components/NextofKins.vue";
import PatientHospitalizations from "../views/patients/components/Hospitalizations.vue";
import HealthConditions from "../views/patients/components/HealthConditions.vue";
import Contraindications from "../views/patients/components/Contraindications.vue";
import MedicalHistories from "../views/patients/components/MedicalHistories.vue";
import Surgeries from "../views/patients/components/Surgeries.vue";
import PatientAppointments from "../views/patients/components/PatientAppointments.vue";
import PatientVitals from "../views/patients/components/PatientVitals.vue";
import EditPatient from "../views/patients/EditPatient.vue";
import ManageDiagnosis from "../views/patients/ManageDiagnosis.vue";
import AddRecord from "../views/records/AddRecord.vue";
import AllRecords from "../views/records/AllRecords.vue";
import SingleRecord from "../views/records/SingleRecord.vue";
import SingleRecordBilling from "../views/records/SingleRecordBilling.vue";
import SpecializedConsultation from "../views/records/components/SpecializedConsultation.vue";
import SingleAppointment from "../views/patients/SingleAppointment.vue";
import SingleLaboratory from "../views/patients/SingleLaboratory.vue";
import SingleHospitalization from "../views/patients/SingleHospitalization.vue";
import UpdateRecord from "../views/records/UpdateRecord.vue";
import ManageRoles from "../views/roles/ManageRoles.vue";
import ManageActivities from "../views/activities/ManageActivities.vue";
import InventoryDashboard from "../views/inventories/InventoryDashboard.vue";
import ManageInventories from "../views/inventories/ManageInventories.vue";
import ManageStocks from "../views/inventories/ManageStocks.vue";
import ManageTransfers from "../views/inventories/ManageTransfers.vue";
import SingleInventory from "../views/inventories/SingleInventory.vue";
import ManageWards from "../views/wards/ManageWards.vue";
import ManageBeds from "../views/wards/ManageBeds.vue";
import ManageDiseases from "../views/diseases/ManageDiseases.vue";
import BloodBank from "../views/bloods/BloodBank.vue";
import BloodDonor from "../views/bloods/BloodDonor.vue";
import BloodDonations from "../views/bloods/BloodDonations.vue";
import BloodOut from "../views/bloods/BloodOut.vue";
import FinanceDashboard from "../views/finances/FinanceDashboard.vue";
import ManageServices from "../views/services/ManageServices.vue";
import ManageInvoices from "../views/finances/ManageInvoices.vue";
import ManagePayments from "../views/finances/ManagePayments.vue";
import ManageBills from "../views/finances/ManageBills.vue";
import SingleBill from "../views/finances/SingleBill.vue";
import EmployeePayroll from "../views/finances/EmployeePayroll.vue";
import ManageAmbulance from "../views/emergencies/ManageAmbulance.vue";
import EmergencyCall from "../views/emergencies/EmergencyCall.vue";
import ManageVaccines from "../views/vaccinations/ManageVaccines.vue";
import VaccinatePatients from "../views/vaccinations/VaccinatePatients.vue";
import BloodEMR from "../views/emr/BloodEMR.vue";
import PharmacyDashboard from "../views/pharmacies/PharmacyDashboard.vue";
import ManagePharmacy from "../views/pharmacies/ManagePharmacy.vue";
import SinglePharmacyProduct from "../views/pharmacies/SinglePharmacyProduct.vue";
import ManageSuppliers from "../views/inventories/ManageSuppliers.vue";
import ManageOrders from "../views/inventories/ManageOrders.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/lock",
    name: "LockScreen",
    component: LockScreen,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
      path: "*",
      redirect: '/404',
      name: "PageNotFound",
      component: PageNotFound,

  },

  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,

},
  {
    path: "/",
    name: "Login",
    component: Login,
  },

  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },

  {
    path: "/downloads",
    name: "Downloads",
    component: Downloads,
  },

  {
    path: "/admin-login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/dashboard",
    component: PersonnelDashboard,
  },
  {
    path: "/admin-dashboard",
    component: AdminDashboard,
  },
  {
    path: "/all-staffs",
    component: AllStaffs,
  },
  {
    path: "/profile",
    component: Profile,
  },

  {
    path: "/add-facility",
    component: AddFacility,
  },
  {
    path: "/single-staff-:id",
    component: SingleStaff,
  },
  {
    path: "/department-:id",
    component: SingleDepartment,
  },
  {
    path: "/edit-staff-:id",
    component: EditStaff,
  },
  {
    path: "/manage-diagnosis",
    component: ManageDiagnosis,
  },

  {
    path: "/add-department",
    component: AddDepartment,
  },
  
  {
    path: "/manage-departments",
    component: ManageDepartments,
  },
  {
    path: "/patients-:type",
    component: AllPatients,
  },
  {
    path: "/add-patient",
    component: AddPatient,
  },
  {
    path: "/patient-:id",
    component: SinglePatient,
    redirect: '/patient-:id/records',
    children: [

      {

        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'records',
        component: PatientRecords,
      },
      {

        path: 'labs',
        component: PatientLabs,
      },

      {

        path: 'nextofkins',
        component: NextofKins,
      },
      {

        path: 'hospitalizations',
        component: PatientHospitalizations,
      },
      {

        path: 'health-conditions',
        component: HealthConditions,
      },
      {

        path: 'contraindications',
        component: Contraindications,
      },
      {

        path: 'medical-histories',
        component: MedicalHistories,
      },
      {

        path: 'surgeries',
        component: Surgeries,
      },
      {

        path: 'vitals',
        component: PatientVitals,
      },
      {

        path: 'appointments',
        component: PatientAppointments,
      },
      
    ]
  },
  {
    path: "/edit-patient-:id",
    component: EditPatient,
  },

  {
    // path: "/add-record/:PatientId/:id",
    path: "/add-record-:id",
    component: AddRecord,
  },

  {
    path: "/all-records",
    component: AllRecords,
  },
  {
    path: "/record-:id",
    component: SingleRecord,
  },
  {
    path: "/recordbilling-:id",
    component: SingleRecordBilling,
  },
  {
    path: "/specialized-:id",
    component: SpecializedConsultation,
  },
  
  {
    path: "/appointment-:id",
    component: SingleAppointment,
  },

  {
    path: "/laboratory/:id",
    component: SingleLaboratory,
  },

  {
    path: "/hospitalization/:id",
    component: SingleHospitalization,
  },

  {
    path: "/edit-record/:PatientId/:id",
    component: UpdateRecord,
  },

  {
    path: "/manage-roles",
    component: ManageRoles,
  },

  {
    path: "/role-:id",
    component: ManageActivities,
  },
  {
    path: "/inventory-dashboard",
    component: InventoryDashboard,
  },
  {
    path: "/manage-inventories",
    component: ManageInventories,
  },
  {
    path: "/inventory-stocks",
    component: ManageStocks,
  },
  {
    path: "/inventory-transfers",
    component: ManageTransfers,
  },
  {
    path: "/inventory/:id",
    component: SingleInventory,
  },
  {
    path: "/suppliers",
    component: ManageSuppliers,
  },

  {
    path: "/purchase-inventory",
    component: ManageOrders,
  },

  {
    path: "/pharmacy-dashboard",
    component: PharmacyDashboard,
  },
  {
    path: "/manage-pharmacy",
    component: ManagePharmacy,
  },
  {
    path: "/pharmacy-product/:id",
    component: SinglePharmacyProduct,
  },

  {
    path: "/manage-wards",
    component: ManageWards,
  },
  
  {
    path: "/manage-beds",
    component: ManageBeds,
  },
  {
    path: "/manage-diseases",
    component: ManageDiseases,
  },
  {
    path: "/blood-bank",
    component: BloodBank,
  },

  {
    path: "/donor",
    component: BloodDonor,
  },
  {
    path: "/blood-donations",
    component: BloodDonations,
  },

  {
    path: "/blood-out",
    component: BloodOut,
  },
  {
    path: "/manage-services/:type",
    component: ManageServices,
  },

  {
    path: "/manage-invoices",
    component: ManageInvoices,
  },
  {
    path: "/finance-dashboard",
    component: FinanceDashboard,
  },
  {
    path: "/manage-payments",
    component: ManagePayments,
  },
  {
    path: "/manage-bills",
    component: ManageBills,
  },
  {
    path: "/bill-:id",
    component: SingleBill,
  },
  {
    path: "/employee-payroll",
    component: EmployeePayroll,
  },

  {
    path: "/manage-ambulance",
    component: ManageAmbulance,
  },

  {
    path: "/emergency-calls",
    component: EmergencyCall,
  },

  {
    path: "/manage-vaccines",
    component: ManageVaccines,
  },
  {
    path: "/vaccinate-patients",
    component: VaccinatePatients,
  },

  {
    path: "/blood-records",
    component: BloodEMR,
  },

];

const router = new VueRouter({
  mode: "history",
  // mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
