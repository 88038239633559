<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Department</label>
                  <v-select v-model="form_data.department_id" class="form-control" label="name"
                    :reduce="(name) => name.id" :options="departments.data" />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Staff </label>
                  <v-select v-model="form_data.staff_id" class="form-control" label="full_name"
                    :reduce="(first_name) => first_name.id" :options="staffs.data" />

                  <b-form-checkbox id="checkbox-1" @input="assignSelf" name="checkbox-1" value="accepted"
                    unchecked-value="not_accepted">
                    With Myself
                  </b-form-checkbox>
                </div>

                <div class="form-group col-md-6">
                  <label>Date</label>
                  <input type="date" placeholder="Chris Rodgers" class="form-control" v-model="form_data.date" />
                </div>

                <div class="form-group col-md-6">
                  <label>Time</label>
                  <input type="time" placeholder="08:00" class="form-control" v-model="form_data.time" />
                </div>
              </div>
              <div class="col-md-12">
                <label for="inputPassword4">Select Status</label>
                <v-select v-model="form_data.status" class="form-control" :options="statuses" />
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea class="form-control" placeholder="Type your message..." rows="4"
                    v-model="form_data.description"></textarea>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <button class="btn btn-success btn-block" type="submit">
                  Submit <i class="las la-paper-plane"></i>
                </button>
              </div>

              <hr class="mt-4 mb-4" />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading :active.sync="isLoading" loader="spinner" :can-cancel="true" :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>
      
<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  props: ["patient"],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      edit: false,
      checked: false,
      categories: "",
      staffs: "",
      departments: "",
      form_data: {
        department_id: "",
        patient_id: "",
        staff_id: "",
        date: "",
        time: "",
        description: "",
        status: "",
      },

      statuses: ["pending", "completed"],
    };
  },
  beforeMount() { },
  methods: {
    assignSelf() {
      this.form_data.staff_id = this.$user.id;
    },
    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.isLoading = true;
      this.axios
        .post("/staff/appointments", {
          department_id: this.form_data.department_id,
          facility_id: this.$selected_facility.id,
          patient_id: this.patient.id,
          staff_id: this.form_data.staff_id,
          date: this.form_data.date,
          time: this.form_data.time,
          description: this.form_data.description,
          status: this.form_data.status,
        })
        .then((response) => {
    console.log(response);
    this.isLoading = false;
    this.$toastr.s("Appointment Scheduled Successfully", "Success!");
    this.clearForm();
})
.catch((error) => {
    console.log(error.response);

    // Check if the error status is 400 (Bad Request)
    if (error.response && error.response.status === 400) {
        // Log the error message for debugging
        console.log("Error Message:", error.response.data.message);

        // Check for specific error messages
        if (error.response.data.message === "Staff is already booked and unavailable") {
            this.$toastr.e("Staff is already booked and unavailable", "Error");
        } else {
            // Handle other error cases
            this.$toastr.e("Error on Adding!", "Error");
        }
    } else {
        // Handle other error cases
        this.$toastr.e("Error on Adding!", "Error");
    }

    this.isLoading = false;
});
    },
    updateDept() {
      //
    },

    getStaffs() {
      this.isLoading = true;

      this.axios
        .get(`/staff/profile`)
        .then((response) => {
          this.staffs = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getDepts() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clearForm() {
      this.form_data.department_id = "";
      this.form_data.staff_id = "";
      this.form_data.date = "";
      this.form_data.time = "";
      this.form_data.description = "";
      this.form_data.status = "";
    },
  },
  created() {
    this.getStaffs();
    this.getDepts();
  },
};
</script>
      