<template>
    <div class="h-100" style="margin-top: 70px">
        <div class="text-center mb-3">
            <a href="/"><img src="images/logos/SmartMedicare.png" class="img-fluid" alt="" width="400px" /></a>
        </div>

        <div class="authincation h-100">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-md-5 col-sm-6 col-xs-6">
                        <div class="authincation-content">
                            <div class="row no-gutters">
                                <div class="col-xl-12">
                                    <div class="auth-form">
                                        <!-- <div class="text-center mb-3">
                        <a href="/"
                          ><img src="images/logos/SmartMedicare.png" alt="" width="400px"
                        /></a>
                      </div> -->
                                        <LoaderComponent v-show="isLoading" />

                                        <h4 class="text-center mb-4 text-white">
                                            Sign up your account
                                        </h4>

                                        <form @submit.prevent="registerFacility">
                                            <!-- <form @submit.prevent="testLogin"> -->
                                            <div class="form-group">
                                                <label class="mb-1 text-white"><strong>Fullname</strong></label>
                                                <input type="text" class="form-control" placeholder="Fullname"
                                                    v-model="auth.admin_fullname" />
                                            </div>


                                            <div class="form-group">
                                                <label class="mb-1 text-white"><strong>Email</strong></label>
                                                <input type="email" class="form-control"
                                                    placeholder="example@uniccon.com" v-model="auth.admin_email" />
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="mb-1 text-white"><strong>Phone Number
                                                                </strong></label>
                                                        <!-- <v-select v-model="auth.admin_gender" required
                                                            class="form-control" :options="gender_data"
                                                            placeholder="Choose" /> -->
                                                            <input type="number" class="form-control"
                                                    placeholder="+2348138355834" v-model="auth.admin_phone" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="mb-1 text-white"><strong>Password</strong></label>
                                                        <input type="password" class="form-control"
                                                            placeholder="Password" v-model="auth.admin_password" />
                                                    </div>
                                                </div>
                                            </div>



                                            <!-- facility_details -->
                                            <div class="form-group">
                                                <label class="mb-1 text-white"><strong>Facilty Name</strong></label>
                                                <input type="text" class="form-control"
                                                    placeholder="Enter your facility name"
                                                    v-model="auth.facility_name" />
                                            </div>

                                            <div class="form-group">
                                                <label class="mb-1 text-white"><strong>Address</strong></label>
                                                <input type="text" class="form-control" placeholder="Enter your address"
                                                    v-model="auth.facility_address" />
                                            </div>

                                            <div class="text-center">
                                                <button type="submit" class="btn bg-white text-success btn-block"
                                                    :disabled="submitting" @click="handleRegistration">
                                                    <span v-if="submitting">Submitting...</span>
                                                    <span v-else>Sign Me Up</span>
                                                </button>
                                            </div>
                                            <br>

                                        </form>

                                        <div class="new-account mt-3">
                                            <p class="text-white">
                                                Already have an account?
                                                <a class="text-white" @click="signin">Sign in</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
        </div>
    </div>

</template>

<script>
import LoaderComponent from "@/views/LoaderComponent.vue";

import axios from 'axios';


export default {
    components: {
        LoaderComponent,
    },
    data() {

        return {

            submitting: false,
            gender_data: ["Male", "Female"],

            auth: {
                admin_fullname: '',
                admin_email: '',
                admin_phone: '',
                admin_password: '',
                facility_name: '',
                facility_address: ''
            },
            errorMessage: "",
            response: "",
            isLoading: false,
            auth_user: "",
            user: null,
        };

    },
    beforeMount() {
        this.user = JSON.parse(localStorage.getItem("user"));
    },


    
    methods: {

        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        toggleModal() {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            this.$refs['my-modal'].toggle('#toggle-btn')
        },

        handleRegistration() {
            // Change the button text to "Submitting..."
            this.submitting = true;

            // Trigger the registerFacility method
            this.registerFacility();
        },
        registerFacility() {
            axios.post('/register-facility', this.auth)
                .then(response => {
                    console.log(response.data);
                    this.$toastr.s("Account Created Successfully");
                    // Redirect to another route after toastr notification
                    this.$router.push('/');
                })
                .catch(error => {
                    // Handle error if necessary
                })
                .finally(() => {
                    // Set submitting back to false after form submission process is complete
                    this.submitting = false;
                });
        },
        signin() {
            this.$router.push('/');
        }
    },
    created() { },
};
</script>