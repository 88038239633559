<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="container-fluid">
        <div class="col-md-8 offset-md-2">
          <div class="card">
            <div class="card-header border-0 pb-4 mb-3 mt-3">
              <h5 class="card-title text-center">Register Patient</h5>
            </div>

            <div class="card-body">
              <form @submit.prevent="submitForm">
                

              

                <div class="form-row">
                <div class="form-group col-md-6">
                  <label class="text-black">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="patient.first_name"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label class="text-black"
                    >Last Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="patient.last_name"
                  />
                </div>
                
                <div class="form-group col-md-6">
                  <label class="text-black">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="patient.middle_name"
                  />
                </div>

                  <div class="form-group col-md-6">
                    <label class="text-black"
                      >Card Number
                      <span class="text-danger">*</span>

                      <a
                        class="btn btn-success btn-sm px-3 light ml-2"
                        @click="autoGenerate"
                        >Generate</a
                      >
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      required
                      v-model="patient.card_no"
                    />
                  </div>

                  <div class="form-group col-md-12">
                    <label class="text-black">Home Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="patient.address"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-black">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="patient.email"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="text-black"
                      >Phone Number <span class="text-danger">*</span></label
                    >
                    <input
                      type="number"
                      required
                      class="form-control"
                      v-model="patient.phone_no"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-black">Occupation</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="patient.occupation"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4"
                      >Select Gender <span class="text-danger">*</span></label
                    >
                    <v-select
                      v-model="patient.gender"
                      required
                      class="form-control"
                      :options="gender_data"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Marital Status</label>
                    <v-select
                      v-model="patient.marital_status"
                      class="form-control"
                      :options="marital_statuses"
                    />
                  </div>

                  <!-- <div class="form-group col-md-6">
                    <label for="inputPassword4">Nationality</label>
                    <v-select
                      v-model="patient.nationality"
                      class="form-control"
                      label="country"
                      :options="countries.countries"
                    />
                  </div> -->

                  <div class="form-group col-md-6">
                    <label>Nationality</label>

                    <select class="form-control" v-model="patient.nationality">
                      <option
                        v-for="country in countries.countries"
                        :key="country.country"
                        :value="country.country"
                      >
                        {{ country.country }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>State of Origin</label>

                    <select
                      class="form-control"
                      v-model="patient.state"
                      @change="getLGA"
                    >
                      <option
                        v-for="state_o in states_lgas"
                        :key="state_o.alias"
                        :value="state_o.state"
                      >
                        {{ state_o.state }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>LGA of Origin</label>

                    <select class="form-control" v-model="patient.lga">
                      <option v-for="lga in lgas" :key="lga">
                        {{ lga }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputPassword4"
                      >Blood Group 
                    </label>
                    <v-select
                      v-model="patient.blood_group"
                      class="form-control"
                      :options="blood_groups"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputPassword4"
                      >Genotype </label
                    >
                    <v-select
                      v-model="patient.genotype"
                      class="form-control"
                      :options="genotypes"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4"
                      >Registration Category
                      <span class="text-danger">*</span></label
                    >
                    <v-select
                      v-model="patient.reg_category"
                      class="form-control"
                      :options="reg_categories"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-black">Age </label>
                    <input
                      type="number"
                      @change="getDOB"
                      class="form-control"
                      v-model="age"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-black">Date of Birth 
                      <span class="text-danger">*</span></label>
                      {{ patient.dob }}
                    </label>
                    <input
                      
                      type="date"
                      :max="maxDate"
                      class="form-control"
                      v-model="patient.dob"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Means of ID </label>
                    <v-select
                      v-model="patient.means_of_identification"
                      class="form-control"
                      :options="identifications"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label class="text-black">ID Number</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="patient.id_number"
                    />  
                  </div>  

                 


                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Emergency Contact Person</label>
                <v-select
                  v-model="contact_person"
                  class="form-control"
                  :options="contactPersons"
                />


                </div>

                  <div class="form-group col-md-6">
                    <label class="text-black">Emergency Contact Phone No</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="patient.contact_person_phone"
                    />  
                  </div>  
                  <div class="form-group col-md-6">
                    <label class="text-black">Emergency Contact Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="patient.contact_person_address"
                    />  
                  </div>  

                   
                </div>

                <div class="form-group">
                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#takePhoto"
                    class="btn btn-outline-success mr-2"
                    @click="cameraready = true"
                    >Take Photo <i class="las la-camera"></i
                  ></a>

                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#uploadPhoto"
                    class="btn btn-outline-success my-2"
                    >Upload Picture <i class="las la-file"></i
                  ></a>
                </div>

                <!-- Snap Picture -->
                <div class="modal fade" id="takePhoto">
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Take Picture</h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="form-group" v-show="cameraready">
                          <web-cam
                            ref="webcam"
                            :device-id="deviceId"
                            width="100%"
                            screenshotFormat="'image/png'"
                            @started="onStarted"
                            @stopped="onStopped"
                            @error="onError"
                            @cameras="onCameras"
                            @camera-change="onCameraChange"
                          />
                        </div>

                        <!-- <div class="form-group">
                          <img :src="img" />
                        </div> -->
                        <div class="form-group" v-show="pictureready">
                          <figure class="figure">
                            <img :src="img" class="img-responsive" />
                          </figure>
                        </div>
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn btn-outline-success mr-3"
                            @click="onCapture"
                          >
                            <i class="las la-check"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-danger mr-3"
                            @click="onStop"
                          >
                            <i class="las la-ban"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-success mr-3"
                            @click="onStart"
                          >
                            <i class="las la-play"></i>
                          </button>

                          <button
                            type="button"
                            class="btn btn-outline-success"
                            @click="onRetake"
                            v-show="retakepicture"
                          >
                            <i class="las la-undo-alt"></i>
                          </button>
                        </div>

                        <div class="form-group mt-2">
                          <button
                            class="btn btn-success btn-block"
                            data-dismiss="modal"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Upload Picture -->
                <div class="modal fade" id="uploadPhoto">
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Upload Picture</h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="form-group">
                          <h5 class="mb-4">
                            <i class="fa fa-paperclip"></i> Upload Patient
                            Picture
                          </h5>
                          <!-- <vue-dropzone
                            ref="myVueDropzone"
                            id="dropzone"
                            :options="dropzoneOptions"
                            v-on:vdropzone-sending="sendingEvent"
                          ></vue-dropzone> -->

                          <div class="input-group mb-3">
                            <!-- <div class="input-group-prepend">
                              <span class="input-group-text">Upload</span>
                            </div> -->
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                v-on:change="attachPic"
                                accept="image/png, image/jpeg"
                              />

                              <label class="custom-file-label"
                                >Choose file</label
                              >
                            </div>
                          </div>
                          <p class="text-success">Max. File Size 2MB</p>
                        </div>

                        <div class="form-group mt-2">
                          <button
                            class="btn btn-success btn-block"
                            data-dismiss="modal"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-success btn-block">
                    Create <i class="las la-user-plus"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { WebCam } from "vue-web-cam";

import { PatientService } from "@/jsstore/patients";
// import { reloadPage } from "@/functions/Settings";
import Countries from "./countries.json";
import States_LGAs from "./states_lgas.json";

export default {
  components: {
    Header,
    Loading,
    // vueDropzone: vue2Dropzone,
    WebCam,
  },
  data() {
    return {
      jsstore: new PatientService(),
      offline_patients: "",
      user: null,
      cameraready: false,
      pictureready: false,
      retakepicture: false,
      isLoading: false,
      fullPage: true,
      age: "",
      Picture: "",
      countries: Countries,
      states_lgas: States_LGAs,
      // lgas: States_LGAs,
      lgas: "",
      reg_categories: [
        "Personal",
        "Family",
        "Mansard",
        "AEDC",
        "Hygeia",
        "NHIS",
        "Ultimate Health",
        "Leadway",
        "THT",
        "Clearline",
        "Anchor",
        "Precious Health",
        "Prepaid Medicare",
        "Swift HMO",
        "Ives HMO",
        "Health Stone",
        "Police HMO",
        "Shongai",
        "DOT",
        "HEALTHCARE SECURITY",
        "IHMS",
        "METRO HEALTH",
        "RELIANCE HMO",
        "VENUS",
        "FIRST GUARANTY",
        "HMO",
        "CORPORATE",
        "BASTION",
        "M.B. AND O",
        "INTEGRATED HEALTH HMO",
        "NOVO",
      ],
      gender_data: ["Male", "Female"],
      marital_statuses: ["Married", "Single", "Divorced"],
      blood_groups: ["A+", "B+", "A-", "B-", "AB+", "AB-", "O+", "0-"],
      genotypes: ["AA", "AS", "AC", "SS", "SC"],
      identifications: [
        "Drivers License",
        "Voters Card",
        "NIN Card",
        "International Passport",
      ],
      patient: {
        id: "",
        facility_id: this.$selected_facility.id,
        initials: "",
        card_no: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        full_name: "",
        phone_no: "",
        email: "",
        blood_group: "",
        genotype: "",
        reg_category: "",
        dob: "",
        gender: "",
        type: "out-patient",
        nationality: "Nigeria",
        state: "",
        lga: "",
        marital_status: "",
        address: "",
        means_of_identification: "",
        id_number: "",
        occupation: "",
        contact_person: "",
        contact_person_phone: "",
        contact_person_address: "",
        password: "jacobi",
      },

      


      
      contactPersons: [
        'Father',
        'Mother',
        'Friend',
        'Spouse',
        'Sibling',
        'Relative',
        'Neighbor',
        'Colleague',
        'Teacher',
        'Classmate',
        // Add more objects as needed
      ],

      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 2.0,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Upload Image",
        headers: { "My-Awesome-Header": "header value" },
      },
      img: "",
      camera: null,
      deviceId: null,
      devices: [],
    };
  },
  mounted() {},

  beforeMount() {},

  computed: {
    maxDate() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;

      return today;
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      // let first = head(this.devices);
      let first = this.devices[0];
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    getDOB() {
      //yyyy-md-dd

      var today = new Date();
      var yyyy = today.getFullYear();
      let yearOfBirth = yyyy - this.age;
      let dob = yearOfBirth + "-" + "01" + "-" + "01";

      console.log(dob);

      this.patient.dob = dob;
    },
    attachPic(event) {
      console.log(event);
      this.Picture = event.target.files[0];
      // this.uploadPicture();
    },
    sendingEvent(file, xhr, formData) {
      formData.append("paramName", "some value or other");
      this.Picture = file;
      console.log(file);
    },
    baseSix() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      // let rawImg;
      reader.onloadend = () => {
        this.patient.Picture = reader.result;
        console.log(this.patient.Picture);
      };
      reader.readAsDataURL(file);
      console.log(file);
    },

    getLGA() {
      let toBeFiltered = this.states_lgas;

      let filtered = toBeFiltered.filter((x) => x.state == this.patient.state);
      this.lgas = filtered[0].lgas;
    },

    submitForm() {
      if (this.$store.state.mode == true) {
        this.addPatient();
      } else {
        this.addPatientOffline();
      }
    },
    addPatient() {
      this.isLoading = true;
      var formData = new FormData();
      formData.append("image_file", this.Picture);
      formData.append("image_captured", this.img);
      formData.append("first_name", this.patient.first_name);
      formData.append("facility_id", this.$selected_facility.id);
      formData.append("last_name", this.patient.last_name);
      formData.append("full_name", this.patient.full_name);
      formData.append("phone_no", this.patient.phone_no);
      formData.append("email", this.patient.email);
      formData.append("reg_category", this.patient.reg_category);
      formData.append("card_no", this.patient.card_no);
      formData.append("gender", this.patient.gender);
      formData.append("dob", this.patient.dob);
      formData.append("blood_group", this.patient.blood_group);
      formData.append("genotype", this.patient.genotype);
      formData.append("password", this.patient.password);
      formData.append("type", this.patient.type);
      formData.append("nationality", this.patient.nationality);
      formData.append("state", this.patient.state);
      formData.append("lga", this.patient.lga);
      formData.append("marital_status", this.patient.marital_status);
      formData.append("address", this.patient.address);
      formData.append("occupation", this.patient.occupation);
      formData.append("contact_person", this.patient.contact_person);
      formData.append("contact_person_phone", this.patient.contact_person_phone);
      formData.append("contact_person_address", this.patient.contact_person_address);
      formData.append(
        "means_of_identification",
        this.patient.means_of_identification
      );
      formData.append("id_number", this.patient.id_number);

      console.log(formData);
      this.axios
        .post("/patient/auth/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          console.log(response);
          this.$toastr.s("Patient Added Successfully", "Success!");
          // this.$router.push("/patients/all");

          this.isLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
          this.$toasted.error("Error", {
            position: "top-center",
            duration: 3000,
          });
        });
    },

    async addPatientOffline() {
      try {
        const patientsAdded = await this.jsstore.addPatient(this.patient);
        console.log(patientsAdded);
        this.resetForm();
        this.$toastr.s("Patient Added Successfully", "Success!");
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },

    resetForm() {
      this.patient.id = "";
      this.patient.first_name = "";
      this.patient.last_name = "";
      this.patient.full_name = "";
      this.patient.phone_no = "";
      this.patient.Picture = "";
      this.patient.email = "";
      this.patient.password = "";
      this.patient.card_no = "";
      this.patient.gender = "";
      this.patient.dob = "";
      this.patient.blood_group = "";
      this.patient.genotype = "";
      this.patient.nationality = "";
      this.patient.state = "";
      this.patient.lga = "";
      this.patient.marital_status = "";
      this.patient.address = "";
      this.patient.occupation = "";
      this.patient.means_of_identification = "";
      this.patient.id_number = "";
    },
    autoGenerate() {
      let length = 10;
      let unique_id = Math.floor(
        Math.pow(10, length - 1) +
          Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
      );

      this.patient.card_no = unique_id;
    },
    onCapture() {
      this.img = this.$refs.webcam.capture();
      console.log(this.img);
      this.cameraready = false;
      this.pictureready = true;
      this.retakepicture = true;
    },

    onStarted(stream) {
  console.log("On Started Event", stream);

  // Set a timeout to stop the webcam after 5 seconds (adjust as needed)
  setTimeout(() => {
    this.$refs.webcam.stop();
  }, 5000);

  console.log("camera stream ended");
},

    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onRetake() {
      this.cameraready = true;
      this.pictureready = false;
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
  },
  created() {
    // this.getPPOff();
  },
};
</script>
