<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Urgency </label>
                  <v-select
                    v-model="form_data.urgency"
                    class="form-control"
                    label="Urgency"
                    :options="urgencies"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Status </label>
                  <v-select
                    v-model="form_data.status"
                    class="form-control"
                    label="Status"
                    :options="statuses"
                  />
                </div>
              </div>
              <br />

              <div class="col-md-12">
                <p class="h4">Sample Details:</p>

                <b-form-group label="Samples:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="samples"
                    v-model="form_data.selected_samples"
                    :options="samples"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3 mt-3 ml-3 mr-3"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <br />

              <div class="col-md-12">
                <p class="h4">Examination Requested</p>

                <b-form-group
                  label="Profile Test:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    id="profile_test"
                    v-model="form_data.profile_test"
                    :options="profiles"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group
                  label="Biochemistry:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    id="biochem"
                    v-model="form_data.biochemistry"
                    :options="biochemistries"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group label="Haematology:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="haeme"
                    v-model="form_data.haematology"
                    :options="haematologies"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group
                  label="Microbiology:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    id="microbiology"
                    v-model="form_data.microbiology"
                    :options="microbiologies"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group
                  label="Anatomical Pathology:"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    id="pathology"
                    v-model="form_data.pathology"
                    :options="pathologies"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="col-md-12">
                <p class="h4">Cervical Cytology</p>

                <b-form-group label="Samples:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="cystology"
                    v-model="form_data.cystology"
                    :options="cystologies"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <br />

              <div class="col-md-12">
                <p class="h4">Radiology Tests</p>
                
                <b-form-group label="Samples:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="radiology"
                    v-model="form_data.radiology"
                    :options="radiologies"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                    class="mb-3"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <br />

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Additional Tests (Describe below): </label>
                  <textarea
                    class="form-control"
                    placeholder="Type your Tests..."
                    rows="4"
                    v-model="form_data.additional"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
      
      <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

import { RecordService } from "@/jsstore/records";

export default {
  components: {
    Loading,
  },
  props: ["patient"],
  data() {
    return {
      record_service: new RecordService(),
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      File: "",
      selected_samples: [],
      services: "",
      roles: "",
      staffs: "",
      form_data: {
        patient_card_no: "",
        patient: "",
        is_lab: true,
        patient_id: "",
        //record fields
        title: "Laboratory Test",
        details: "see lab for details",
        brief: "see lab for details",
        lab: "",
        action: "end visit",
        action_id: "",
        next_id: "",

        //lab fields
        urgency: "normal",
        selected_samples: [],
        profile_test: [],
        biochemistry: [],
        haematology: [],
        microbiology: [],
        pathology: [],
        cytology: [],
        additional: "",
        status: "completed",
      },
      statuses: ["pending", "completed"],
      samples: [
        "Blood",
        "Faeces",
        "Urine",
        "Swab",
        "Tissue",
        "Sputum",
        "Fluids",
        "Cytology",
      ],
      microbiologies: [
        "Urine FEME",
        "RPR (VDRL)",
        "Microscopy/Culture/Sensitivity",
        "AFB (ZN) Smear Only",
      ],
      profiles: ["G2000", "G 2000-X", "GT9", "GTI"],
      biochemistries: ["CEA", "CA 1", "CA 5", "CA 9"],
      haematologies: ["FBE (incl. ESR)", "Hb", "TWDC", "Platelets"],
      pathologies: ["Histology", "Non-Gynae/FNA"],
      cystologies: ["Pap Smear", "Normal", "Post-Mono Blood", "Susp lesion"],
      urgencies: ["normal", "urgent"],
      radiologies: ["CT scan (computed axial tomography or CAT scan)", "Magnetic resonance imaging (MRI) scan", "CT lung cancer screening", "Ultrasound", "CT virtual colonoscopy", "X-rays (radiographs)", "Fluoroscopy"],
    };
  },
  beforeMount() {
   
  },
  computed: {},
  methods: {
    submitForm() {
      if (this.$store.state.mode == true) {
        this.addLabOnline();
      } else {
        this.addRecordOffline();
      }
    },
    async addRecordOffline() {
      try {
        let lab_data = {
          urgency: this.form_data.urgency,
          selected_samples: this.form_data.selected_samples,
          profile_test: this.form_data.profile_test,
          biochemistry: this.form_data.biochemistry,
          haematology: this.form_data.haematology,
          microbiology: this.form_data.microbiology,
          pathology: this.form_data.pathology,
          cytology: this.form_data.cytology,
          additional: this.form_data.additional,
          status: "pending",
        };

        this.form_data.patient = this.patient;
        this.form_data.lab = lab_data;
        this.form_data.patient_card_no = this.patient.card_no;
        let query = await this.record_service.addRecord(this.form_data);
        console.log(JSON.stringify(query) + "jsstore");
        this.$toastr.s("Laboratory Order Successfully", "Success!");
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },
 
    addLabOnline() {
      this.isLoading = false;

      let lab_data = {
          selected_samples: this.form_data.selected_samples,
          profile_test: this.form_data.profile_test,
          biochemistry: this.form_data.biochemistry,
          haematology: this.form_data.haematology,
          microbiology: this.form_data.microbiology,
          pathology: this.form_data.pathology,
          cytology: this.form_data.cytology,
          additional: this.form_data.additional,
        };
      this.axios
        .post(`/staff/labs`,{
          patient_id: this.patient.id,
          staff_id: this.$user.id,
          // record_id: this.$route.params.id,
          record_id:  this.$route.path == `/record/${this.$route.params.id}`  ? this.$route.params.id : null,

          urgency: this.form_data.urgency,
          // lab_tests: JSON.stringify(lab_data),
          lab_tests: lab_data,
          status: this.form_data.status,

        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Lab Record Stored Successfully", "Success!");
      this.clearForm()
       
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clearForm(){
      this.form_data.selected_samples = []
      this.form_data.profile_test = []
      this.form_data.biochemistry = []
      this.form_data.haematology = []
      this.form_data.microbiology = []
      this.form_data.pathology= []
      this.form_data.cytology= []
      this.form_data.additional= ""
    }
  },
  created() {
  },
};
</script>
      