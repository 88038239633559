<template>
  <div class="deznav">
    <div class="deznav-scroll">
      <ul class="metismenu" id="menu">
        <li>
          <a class="" href="javascript:void()">
            <i class="las la-signal la-2X" style="visibility: hidden"></i>

            <toggle-button v-model="$store.state.mode" disabled color="#097969" width="60" height="29" />
          </a>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="flaticon-381-networking"></i>
            <span class="nav-text">My Account</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/admin-dashboard" v-if="$user_type == 'admin'">Admin</a>
            </li>
            <li>
              <a href="/dashboard" v-if="$user_type == 'staff'">Dashboard</a>
            </li>
            <!-- <li><a href="#">My appointments</a></li> -->
            <li><a href="/settings">Settings</a></li>
            <!-- <li><a href="/downloads" >Downloads</a></li> -->
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-user-injured la-5x"></i>
            <span class="nav-text">Patients </span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/add-patient"> Add Patient </a>
            </li>
            <li v-for="patient in patient_lists" v-bind:key="patient.id">
              <a :href="`/patients-${patient.type}`">{{ patient.name }}
              </a>
            </li>

            <li>
              <a href="/all-records">All Records</a>
            </li>

          </ul>
        </li>

        <li v-if="$user_type == 'admin'">
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-user-friends la-2x"></i>
            <span class="nav-text">Staff </span>
          </a>
          <ul aria-expanded="false">
            <li><a href="/all-staffs">Manage Staff</a></li>
            <li><a href="/manage-roles">Roles Manager</a></li>
          </ul>
        </li>

        <li v-if="$user_type == 'admin' ||
          $user.job_title.name == 'Cashier' ||
          $user.job_title.name == 'Accountant'
          ">
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="mdi mdi-currency-ngn"></i>
            <span class="nav-text">Finance & Billing </span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/finance-dashboard">Dashboard</a>
            </li>
            <li>
              <a href="/manage-payments">Expenses/Payments</a>
            </li>
            <li>
              <a href="/employee-payroll">Employee Payroll</a>
            </li>
            <li><a href="/manage-bills">Bills</a></li>
            <li><a href="manage-services">Services</a></li>
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="mdi mdi-folder"></i>
            <span class="nav-text">Departments </span>
          </a>
          <ul aria-expanded="false">
            <li v-if="$user_type == 'admin'">
              <a href="/manage-departments">Manage Departments</a>
            </li>
            <li v-for="dept in departments.data" v-bind:key="dept.id">
              <a :href="`/department-${dept.id}`">
                {{ dept.name }}
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-prescription-bottle la-2x"></i> <span class="nav-text">Services & Drugs </span>
          </a>
          <ul aria-expanded="false">
            <li v-if="$user_type == 'admin'">
              <a :href="`/manage-services/Services`"><i class="las la-file-invoice-dollar mr-2"></i> Service
                Fees</a>
            </li>
            <li v-if="$user_type == 'admin'">
              <a href="/manage-services/Drugs"><i class="las la-tablets mr-2"></i> Drugs & Fees</a>
            </li>
            <li v-if="$user_type == 'admin'">
              <a href="/manage-diseases"><i class="las la-medkit mr-2"></i> Health Conditions</a>
            </li>

            <li>
              <a href="/manage-ambulance"><i class="las la-ambulance mr-2"></i> Ambulance</a>
            </li>

            <li>
              <a href="/emergency-calls"><i class="las la-phone-volume mr-2"></i> Emergency
                Calls</a>
            </li>
          </ul>
        </li>



        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-file-medical"></i>
            <span class="nav-text">EMR</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/all-records">Facility Records</a>
            </li>
            <li>
              <a href="/blood-records">Blood Records</a>
            </li>
            <li><a href="#">Laboratory Records</a></li>
            <li><a href="#">General Records</a></li>
            <li><a href="#">Hospitalizations</a></li>
          </ul>
        </li>

        <!-- v-if="$user.job_title.name == 'Pharmacy'" -->
        <li v-if="$user_type == 'admin' || $user.job_title.name == 'Inventory'">
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-clipboard la-2x"></i>
            <span class="nav-text">Inventory Management </span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/inventory-dashboard">Dashboard</a>
            </li>

            <li>
              <a href="/manage-inventories">Inventories</a>
            </li>
            <li><a href="/inventory-stocks">Stocks</a></li>
            <li><a href="/suppliers">Suppliers</a></li>
            <li>
              <a href="/purchase-inventory">Purchase/Receive Inventory</a>
            </li>
            <li>
              <a href="/inventory-transfers">Transfer Stock</a>
            </li>
          </ul>
        </li>

        <li v-if="$user_type == 'admin' || $user.job_title.name == 'Pharmacy'">
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-prescription la-3x"></i>
            <span class="nav-text">Pharmacy Management </span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/pharmacy-dashboard">Dashboard</a>
            </li>
            <li>
              <a href="/manage-pharmacy">Drugs & Dispensaries
              </a>
            </li>
          </ul>
        </li>

        <li v-if="$user_type == 'admin'">
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-procedures la-3x"></i>
            <span class="nav-text">Ward Manager </span>
          </a>
          <ul aria-expanded="false">
            <li><a href="/manage-wards">Manage Wards</a></li>
            <li><a href="/manage-beds">Manage Beds</a></li>
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-syringe"></i>
            <span class="nav-text">Vaccinations</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/manage-vaccines">Manage Vaccinations</a>
            </li>
            <li>
              <a href="/vaccinate-patients">Vaccinate Patients</a>
            </li>
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="mdi mdi-monitor"></i>
            <span class="nav-text">Front Desk</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="/emergency-calls">Emergency Calls</a>
            </li>
            <li><a href="#">Visitors</a></li>
            <!-- <li><a href="reviews.html">Postal Receive</a></li> -->
            <!-- <li><a href="reviews.html">Postal Dispatch</a></li> -->
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-tint"></i>
            <span class="nav-text">Blood Bank </span>
          </a>
          <ul aria-expanded="false">
            <li><a href="/blood-bank">Blood Banks</a></li>
            <li><a href="/donor">Blood Donors</a></li>
            <li>
              <a href="/blood-donations">Blood Donations</a>
            </li>
            <li><a href="/blood-out">Blood Issued Out</a></li>
          </ul>
        </li>

        <li>
          <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
            <i class="las la-cog la-2x"></i>
            <span class="nav-text">Settings</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <a href="#" v-if="$user_type == 'admin'">Hospital Settings</a>
            </li>
            <li><a href="#">Notice Board</a></li>
          </ul>
        </li>

        <li>
          <a class="" href="https://smartmedicares.com/#/contact-us" aria-expanded="false">
            <i class="las la-comment la-2x"></i>
            <span class="nav-text">Feedback</span>
          </a>
        </li>
      </ul>

      <div class="copyright">
        <p><strong>Uniccon SmartMedicare</strong> © 2024 All Rights Reserved</p>
        <p>Powered by Uniccon Group</p>
      </div>
    </div>

    <div class="vld-parent">
      <loading :active.sync="isLoading" loader="spinner" :can-cancel="true" :is-full-page="fullPage"></loading>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { DeptService } from "@/jsstore/depts";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      jsstore: new DeptService(),
      checked: false,
      user: null,
      isLoading: false,
      fullPage: true,
      inventories: "",
      departments: "",
      patient_lists: [
        {
          id: 1,
          name: "All Patients",
          type: "all",
        },
        {
          id: 2,
          name: "In Patients",
          type: "in-patient",
        },
        {
          id: 2,
          name: "Out Patients",
          type: "out-patient",
        },
      ],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    openBrowser() {
      window.open("https://www.facebook.com", "_blank");
    },
    getDepts() {
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getDeptsOffline() {
      let query = await this.jsstore.getDepts();
      this.departments = {
        data: query,
      };
    },
  },
  created() {
    if (this.$store.state.mode == false) {
      this.getDeptsOffline();
    } else {
      this.getDepts();
    }
  },
};
</script>
